import React from 'react'
import { IButtonProps } from './types'
import './styles.css'

const Button: React.FC<IButtonProps> = ({ text, type, onClick }) => {
    return (
        <div className={`buttonContainer ${type}`} onClick={onClick}>
            {text}
        </div>
    )
}

export default Button