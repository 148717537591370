export const getDate = () => {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
  
    return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year.toString().slice(2)}`;
}

export const validatePhoneNumber = (number: string) => {
    const PHONE_REGEX = /^[6-9][0-9]{9}$/
    return PHONE_REGEX.test(number)
}
