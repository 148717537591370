import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import './App.css'
import Book from './containers/Book';
import Home from './containers/Home';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/book" Component={Book} />
        <Route path="/" Component={Home} />
      </Routes>
    </Router>
  )
}

export default App;
