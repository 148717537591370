import React from 'react';
import './header.css';

const Header: React.FC = () => {
  return (
    <>
      <div className="headerContainer">
          <div className="title">Asha Swaroop Homeo Clinic</div>
      </div>
      <div className="emptyDiv"/>
    </>
  );
}

export default Header;
