const server = 'https://us-central1-doctrax-server.cloudfunctions.net/app';

export const fetchApi = async (
  endpoint: string,
  method: 'GET' | 'POST',
  body?: object,
) => {
  let response: Response;
  if (method === 'GET') {
    response = await fetch(`${server}${endpoint}`);
  } else {
    response = await fetch(`${server}${endpoint}`, {
      method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
    });
  }

  return response.json();
}
