import React from 'react'
import { ITokenTileProps } from './types'
import './styles.css'

const TokenTile: React.FC<ITokenTileProps> = ({ title, value }) => {
    return (
        <div className="tileWrapper">
            <div className="tileChildren">
                <div className="tileValue">{value}</div>
                <div className="tileTitle">{title}</div>
            </div>
        </div>
    )
}

export default TokenTile