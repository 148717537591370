import { fetchApi } from "../../utils/api"

export const bookAppointment = async (
    name: string,
    phone: string,
    onSuccess: (token: number) => void,
    onFailure: (error: string) => void
) => {
    try {
        const response = await fetchApi('/patient', 'POST', { name, phone })

        if (response.status === 200 && response.token_no) {
            onSuccess(response.token_no)
        } else {
            throw response
        }
    } catch(e: any) {
        onFailure(e.message ?? 'Oops! Something went wrong. Please try again.')
        console.log(e)
    }
}
