import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Header from '../../components/Header';
import { getDate, validatePhoneNumber } from '../../utils/common';
import './styles.css'
import { bookAppointment } from './utils';

interface IAlert {
    msg: string | number;
    type: 'success' | 'error';
}

const Book: React.FC = () => {
    const [alert, setAlert] = useState<IAlert>()
    const [isBooking, changeIsBooking] = useState(false);

    const alertSetter = (value: IAlert, delay: number) => {
        setAlert(value)
        setTimeout(() => {
            setAlert(undefined)
        }, delay)
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        changeIsBooking(true);

        const form = event.currentTarget;
        const name = form.name.value;
        const phone = form.phone.value;

        if (!validatePhoneNumber(phone)) {
            alertSetter({ msg: 'Please enter a valid phone number', type: 'error' }, 3000)
            changeIsBooking(false);
            form.reset()
            return
        }

        bookAppointment(
            name,
            phone,
            token => {
                changeIsBooking(false);
                alertSetter({ msg: token, type: 'success' }, 10000);
            },
            err => {
                changeIsBooking(false);
                alertSetter({ msg: err, type: 'error' }, 6000);
            }
        )

        form.reset()
    }

    return (
        <>
            <Header />
            {alert?.type === 'success' && (
                <Alert variant="success">
                    <h3>Booking Successful</h3>
                    Your token number is <span className="highlight">{alert.msg}</span>. For timing contact <span className="highlight">9219292444</span>
                </Alert>
            )}
            {alert?.type === 'error' && (
                <Alert variant="danger">
                    {alert.msg}
                </Alert>
            )}
            <Form key={1} onSubmit={handleSubmit} className="form">
                <Form.Group controlId="name" className="formGroup">
                <Form.Label>Patient Name</Form.Label>
                <Form.Control required placeholder="Enter Patient Name" />
                </Form.Group>

                <Form.Group controlId="phone" className="formGroup">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control required placeholder="Enter Contact Number" />
                <Form.Text>
                    Booking can only be tracked using this phone number.
                </Form.Text>
                </Form.Group>

                <Form.Group controlId="doctor" className="formGroup">
                <Form.Label>Doctor</Form.Label>
                <Form.Control value="Dr. Akhilesh kumar Saxena" disabled />
                </Form.Group>

                <Form.Group controlId="date" className="formGroup">
                <Form.Label>Date</Form.Label>
                <Form.Control value={getDate()} disabled />
                </Form.Group>

                {
                    isBooking
                        ? (
                            <Button variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                {` `}Booking...
                            </Button>
                        ) : (
                            <Button variant="primary" type="submit">
                                Book
                            </Button>
                        )
                }
            </Form>
        </>
    )
}

export default Book;
