import React, { useEffect, useState } from 'react'
import Header from '../../components/Header';
import TokenTile from '../../components/TokenTile';
import Button from '../../components/Button';
import { fetchApi } from '../../utils/api';

const Home: React.FC = () => {
  const [tokens, setTokens] = useState<{ current: number; total: number }>({ current: 0, total: 0 })

  const fetchTokens = async () => {
    try {
      const res = await fetchApi('/tokens', 'GET');

      const { current = 0, total = 0 } = res || {};

      if (current !== tokens.current || total !== tokens.total) {
        setTokens({ current, total })
      }
    } catch(e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchTokens()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Header />
      <TokenTile title="Currently Examining" value={tokens.current} />
      <TokenTile title="Total Tokens Allotted" value={tokens.total} />
      <Button text="Book Appointment" type="info" onClick={() => window.location.pathname = '/book'} />
    </>
  );
}

export default Home;
